import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { PopupOpenEventArgs, View } from '@syncfusion/ej2-angular-schedule';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { DoctorTimeSlots } from '../model';
import { DataproviderService } from '../services/dataprovider.service';
@Component({
  selector: 'app-timeslotpage',
  templateUrl: './timeslotpage.component.html',
  styleUrls: ['./timeslotpage.component.css']
})
export class TimeslotpageComponent implements OnInit {
  isEmpty= true;
  // PSreason  = this.navCtrl.get('reason');
  PSreason: any;
  AllSlots: any;
  date: any;
  responce: any;
  AllDoctors: any;
  todayDate = this.datepipe.transform(Date.now() , 'yyyy-MM-dd');
  checkSlot : any;
  SendOj: any;
  DoctorName: any;
  constructor(
    private modalService: NgbModal,
    private dataservice: DataproviderService,
    public router:Router,
    public navCtrl: NgxNavigationWithDataComponent,
    public datepipe: DatePipe,
    ) { }

  @ViewChild('div') public popover: NgbPopover;
  @ViewChild('content',{static:false}) content:any

  public  reason = true;
  public timeSlot = true
  public appointmentObj:any;
  public dateFromSchedular:any;
  public currentView: View = 'Month';
  public otherDaySlots:any;

  ngOnInit(): void {
    this.reason = true;
    this.PSreason = localStorage.getItem('reason');
    this.onTimeSlot();

    var obj = {a: true, b: true, c: false, d: false}
    localStorage.setItem('timeline', JSON.stringify(obj) );

    // if (this.PSreason == undefined) {
    //   this.router.navigate(['/firstPage']);
    // }

  }

  openLg(content,a,ab,day) {
    let modal:any=  document.getElementById('closeBtn2')
    if(modal){
      modal.click();
    }
    console.log(a)
    this.dataservice.Doctor = ab;
    if(day =='today'){
      let today = new Date().toISOString().slice(0, 10)
      this.appointmentObj = {
        appointmentDate: today,
        appointmentTime: a.appointmentTime,
        appointmentLenght: a.appointmentLength,
        practitionerID: ab.userid,
        patientID: 0,
        loginID: 0,
        locationId: a.locationId,
        videoUrl: "string"
      }

    }
    else if(day=='Tommorow'){
      let tomorrow = new Date();
      tomorrow.setDate(new Date().getDate()+1);
      let today = tomorrow.toISOString().slice(0, 10)
      this.appointmentObj = {
        appointmentDate: today,
        appointmentTime: a.appointmentTime,
        appointmentLenght: a.appointmentLenght,
        practitionerID: ab.userid,
        patientID: 0,
        loginID: 0,
        locationId: a.locationId,
        videoUrl: "string"
      }
    }

    this.modalService.open(content, { size: 'lg', centered: true });
  }

  onSeeAllTime(times,doctor){
    console.log(doctor);
    this.dataservice.Doctor = doctor
    this.modalService.open(times, {size : 'lg', centered : true});
  }

  onClick(){
    this.popover.close();
  }

  onTimeSlot(){
     this.dataservice.onfetchTimeSlots().subscribe(
      (data) => {
        console.log(data);

        this.responce = data;

        this.responce.forEach(element => {

          if(element.freeAppointments.length>0){

            element.freeAppointments.forEach(element1 => {
            let x =[];
            element1.dailyAppointments.forEach((element2,index) => {
                  if(index<4){


                      x.push(element2)
                  }
            });
            element1.dailyAppointments = x;
            });
          }

        });

        console.log(this.responce);

      this.isEmpty = false;


      });

  }

  time(value: number) {
    var minutes: any = Math.floor(value / 60);
    var hours: number = Math.floor(minutes / 60);
    minutes = Math.floor(minutes % 60);
    var mins: string = "0";
    if (minutes == 0) {
      minutes = minutes + "" + mins;
    }
    var time = 'AM'
    if (hours >= 12) {
      hours = hours - 12;
      time = 'PM'
    }

    return hours + ":" + minutes + " " + time;
  }

  toNewPatient = () => {
    document.getElementById('closeBtn').click()
    this.dataservice.appoimentObj_1.addAppointmentModel = this.appointmentObj
    this.router.navigate(['/NewPatient'])
  }

  toExistingPatient = () => {
    document.getElementById('closeBtn').click();
    this.dataservice.appoimentObj_1.addAppointmentModel = this.appointmentObj
    this.router.navigate(['/patientdetail'])
  }

  onPopupOpen(args: PopupOpenEventArgs): void {
    console.log(args);
      this.dateFromSchedular = args.data;
      console.log(this.dateFromSchedular.startTime)
      let x = {
        consultantID: this.dataservice.Doctor.userid,
        sessionDate: this.dateFromSchedular.endTime
      }
      this.dataservice.getGetConsutantAvailableTimeslotByDate(x).subscribe((res)=>{
        console.log(res);
        this.otherDaySlots = res;
        this.otherDaySlots.forEach(element => {

          if(element.freeAppointments.length>0){

            element.freeAppointments.forEach(element1 => {
            let x =[];
            element1.dailyAppointments.forEach((element2,index) => {
                  if(index<4){


                      x.push(element2)
                  }
            });
            element1.dailyAppointments = x;
            });
          }

        });
      })
    if (args.type === 'Editor' || args.type === 'QuickInfo')  {
        args.cancel = true;
    }
  }

  /*Imran Code*/
  getSlotsByDate() {
    let data = {
      consultantID: 0,
      sessionDate: this.datepipe.transform(this.date , 'yyyy-MM-dd')
    };
    this.dataservice.getSlotsBydate(data).subscribe((res:any)=>{
      this.AllSlots = res;
      console.log(this.AllSlots);
      this.checkSlot = this.secondsTodate(this.AllSlots[0].starttime);
      this.SendOj = {
        appointmentLenght: this.AllSlots[0].length,
        appointmentTime: this.AllSlots[0].starttime,
        appointmentDate: this.datepipe.transform(this.date , 'yyyy-MM-dd'),
        practitionerID: 0,
        patientID: 0,
        loginID: 0,
        locationId: 0,
        videoUrl: "string"
      };

      this.dataservice.GetDoctorBySlot(this.SendOj).subscribe((res:any)=>{
        this.AllDoctors = res;
      });
    });


  }

  CheckDoctors() {
    console.log(this.checkSlot)
    let start = this.checkSlot.split(',');
    this.SendOj = {
      appointmentLenght: JSON.parse(start[1]),
      appointmentTime: JSON.parse(start[0]),
      appointmentDate: this.datepipe.transform(this.date , 'yyyy-MM-dd'),
      practitionerID: 0,
      patientID: 0,
      loginID: 0,
      locationId: 0,
      videoUrl: "string"
    };

    this.dataservice.GetDoctorBySlot(this.SendOj).subscribe((res:any)=>{
      this.AllDoctors = res;
    });
  }

  goClient(item) {
    this.SendOj.practitionerID = item.userid;
    this.DoctorName = item.title +' '+ item.firstname +' '+ item.surname;
    document.getElementById('OpenModal').click();
  }

  new() {

    localStorage.setItem('Appoinment' , JSON.stringify(this.SendOj));
    localStorage.setItem('doctorName' , this.DoctorName);
    this.navCtrl.navigate('/NewPatient');
    document.getElementById('CloseModal').click();
  }

  already() {

    localStorage.setItem('Appoinment' , JSON.stringify(this.SendOj));
    localStorage.setItem('doctorName' , this.DoctorName);
    this.navCtrl.navigate('/patientdetail');
    document.getElementById('CloseModal').click();
  }

  closeModal() {
    document.getElementById('CloseModal').click();
    this.router.navigate(['/Reason']);
  }
  secondsTodate(seconds) {
    var days = Math.floor(seconds/86400);
    var hours = Math.floor((seconds % 86400) / 3600);
    var mins = Math.floor(((seconds % 86400) % 3600) / 60);
    return (days > 0 ? days+'d ' : '') + ('00'+hours).slice(-2) +':' + ('00'+mins).slice(-2);
    };

}
