import { Component, OnInit } from '@angular/core';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';

@Component({
  selector: 'app-firstpage',
  templateUrl: './firstpage.component.html',
  styleUrls: ['./firstpage.component.css']
})
export class FirstpageComponent implements OnInit {

  constructor(
    public navCtrl: NgxNavigationWithDataComponent
  ) { }

  ngOnInit(): void {
    var obj = {a: false, b: false, c: false, d: false}
    localStorage.setItem('timeline', JSON.stringify(obj) );
    localStorage.clear()
  }

  avaiable() {
    localStorage.setItem('page', 'firstavailable');
    this.navCtrl.navigate('/Reason');
    // this.navCtrl.navigate('/Reason', {page: 'firstavailable'});
  }

  particular() {
    localStorage.setItem('page', 'particular');
    this.navCtrl.navigate('/Reason');
    // this.navCtrl.navigate('/Reason', {page: 'particular'});
  }

}
