<app-header></app-header>
<div class="main">
    <div class="container">

        <div class="row text-center justify-content-center">
            <div class="col-12 col-md-6 col-lg-5">
                <div class="card thanksCard">
                    <div class="text-center confirmText">
                        <span>Thank you {{patientName}}</span>
                        <br>
                        <br> Your appointment is now confirmed.
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center text-center">
            <button [routerLink]="['/firstPage']" class="done">Done</button>
        </div>

        <div class="row justify-content-center text-center">
            <button class="thanksText">Thank you for visiting EHMC. Have a nice day</button>
        </div>

    </div>

</div>
<div class="footer">
    <p>Site powered by Meditco trading as Ozbiztech Pty Ltd</p>
</div>