<img src="../../assets/images/Mask Group 8.png" alt="" class="BackGroundNavbar">
<div class="nav1">
    <a class="navbar-brand" routerLink="/firstPage">
        <div class="row onlineBookingIcon m-0 p-3">
            <div class="col-12 col-md-6 col-lg-6">
                <img src="../../assets/images/1.png" alt="" class="logo">
            </div>
            <div class="col-12 col-md-6 col-lg-6 onlineBooking text-right justify-content-end">
                <div id="OnLineBooking_P" class="pr-5">
                    <span>Online</span>
                    <br>
                    <span>Booking</span>
                    <p id="madeeasy">Your appoinment made easy</p>
                </div>
            </div>
        </div>
    </a>
</div>

<div class="nav2">
    <a class="navbar-brand" routerLink="/firstPage">
        <div class="row onlineBookingIcon m-0 p-3">
            <div class="col-6 col-md-6 col-lg-6">
                <img src="../../assets/images/2.png" alt="" class="logo">
            </div>
            <div class="col-6 col-md-6 col-lg-6 onlineBooking text-right justify-content-end">
                <div id="OnLineBooking_P" class="pr-5">
                    <span>Online</span>
                    <br>
                    <span>Booking</span>
                    <p id="madeeasy">Your appoinment made easy</p>
                </div>
            </div>
        </div>
    </a>
</div>