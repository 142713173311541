<div class="timeline">
    <div class="events">
        <ol>
            <ul class="ul1">
                <li class="l1">
                    <a class="a1 selected" *ngIf="TimeLine?.a == true">
                      Select Reason
                    </a>

                    <a class="a1" *ngIf="TimeLine?.a == false">
                      Select Reason
                    </a>
                </li>
                <li class="l2">
                    <a class="a2 selected" *ngIf="TimeLine?.b == true">
                       Choose Timeslot
                    </a>

                    <a class="a2" *ngIf="TimeLine?.b == false">
                      Choose Timeslot
                    </a>
                </li>
                <li class="l3">
                    <a class="a3 selected" *ngIf="TimeLine?.c == true">
                      Provide Details
                    </a>

                    <a class="a3" *ngIf="TimeLine?.c == false">
                      Provide Details
                    </a>
                </li>
                <li class="l4">
                    <a class="a4 selected" *ngIf="TimeLine?.d == true">
                      Confirm
                    </a>

                    <a class="a4" *ngIf="TimeLine?.d == false">
                      Confirm
                    </a>
                </li>
            </ul>


            <ul class="ul2">
                <li class="l1">
                    <a class="a1 selected" *ngIf="TimeLine?.a == true">
                    Reason
                  </a>

                    <a class="a1" *ngIf="TimeLine?.a == false">
                    Reason
                  </a>
                </li>
                <li class="l2">
                    <a class="a2 selected" *ngIf="TimeLine?.b == true">
                     Timeslot
                  </a>

                    <a class="a2" *ngIf="TimeLine?.b == false">
                    Timeslot
                  </a>
                </li>
                <li class="l3">
                    <a class="a3 selected" *ngIf="TimeLine?.c == true">
                    Details
                  </a>

                    <a class="a3" *ngIf="TimeLine?.c == false">
                    Details
                  </a>
                </li>
                <li class="l4">
                    <a class="a4 selected" *ngIf="TimeLine?.d == true">
                    Confirm
                  </a>

                    <a class="a4" *ngIf="TimeLine?.d == false">
                    Confirm
                  </a>
                </li>
            </ul>

        </ol>
    </div>
</div>