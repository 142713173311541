import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CheckedinComponent } from './checkedin/checkedin.component';
import { ConfirmappointmentComponent } from './confirmappointment/confirmappointment.component';
import { ConfirmcheckedinComponent } from './confirmcheckedin/confirmcheckedin.component';
import { FirstpageComponent } from './firstpage/firstpage.component';
import { HeaderComponent } from './header/header.component';
import { MakingappointmentComponent } from './makingappointment/makingappointment.component';
import { NewpatientformComponent } from './newpatientform/newpatientform.component';
import { PatientdetailComponent } from './patientdetail/patientdetail.component';
import { ReasonpageComponent } from './reasonpage/reasonpage.component';
import { SuccesspageComponent } from './successpage/successpage.component';
import { TimeslotpageComponent } from './timeslotpage/timeslotpage.component';
import { SeeDoctorComponent } from './see-doctor/see-doctor.component';
import { AvailaleDCComponent } from './availale-dc/availale-dc.component';
import { SelectTimesComponent } from './select-times/select-times.component';
import { CancelAppoinmentComponent } from './cancel-appoinment/cancel-appoinment.component';

const routes: Routes = [
  { path: '',component: FirstpageComponent, pathMatch: 'full' },
  { path: 'firstPage', component:FirstpageComponent},
  { path: 'firstPage', component:FirstpageComponent},
  { path: "firstPage", component:FirstpageComponent},
  { path: "Reason", component: ReasonpageComponent },
  { path: "SuccessPage",component:SuccesspageComponent},
  { path: "NewPatient",component:NewpatientformComponent},
  { path: "timeslot", component: TimeslotpageComponent },
  { path: "seeDoctor", component: SeeDoctorComponent },
  { path: "patientdetail", component: PatientdetailComponent },
  { path: "doctors", component: AvailaleDCComponent },
  { path: "selectTime", component: SelectTimesComponent },
  { path: "confirmappoinment", component: ConfirmappointmentComponent },
  { path: "cancelAppoinment", component: CancelAppoinmentComponent },
  { path: "checkedin", component: CheckedinComponent },
  { path: "confirmcheckedin",component:ConfirmcheckedinComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)
  , RouterModule.forRoot(routes, { useHash: true })],
  
  exports: [RouterModule]
})
export class AppRoutingModule { }
