<app-header></app-header>

<div class="main">
    <div class="container">
        <!--Time Line Row # 1-->
        <div class="row first_Row">
            <div class="card first_card">
                <div class="card-body">
                    <app-makingappointment></app-makingappointment>
                </div>
            </div>
        </div>


        <!--Detail Final-->
        <div class="row justify-content-center mt-5">
            <div class="col-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body p-5">

                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-5">
                                <ngb-datepicker [(ngModel)]="ModelDate" name="date1" (dateSelect)="changeDate($event)" [displayMonths]="1" [navigation]="true"></ngb-datepicker>
                            </div>
                            <div class="col-12 col-md-16 col-lg-7">
                                <div id="label" *ngIf="AllSlots?.length >= 1">{{ModelDate}} Available Slots</div>
                                <br>

                                <div *ngIf="AllSlots?.length <= 0">
                                    <div class="row text-center justify-content-center">
                                        <p>No Slots Available</p>
                                    </div>
                                </div>

                                <div class="slot" *ngFor="let slot of AllSlots;let k =index">
                                    <p (click)="GetSlot(item?.dateOfDay , item?.userid , item?.firstname , slot)">{{secondsTodate(slot?.appointmentTime)}}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>


        <br><br>
        <br><br>
        <br><br>
        <br><br>
        <br><br>
    </div>
</div>

<div class="footer">
    <p>Site powered by Meditco trading as Ozbiztech Pty Ltd</p>
</div>


<!-- Button trigger modal -->
<button type="button" hidden id="OpenModal" data-toggle="modal" data-target="#CheckModal"></button>

<!-- Modal -->
<div class="modal fade" id="CheckModal" tabindex="-1" role="dialog" aria-labelledby="CheckModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="row justify-content-center text-center">
                <p class="pt-4">Have you see by the doctor in this surgey before ?</p>
                <div class="bottomDiv p-0 pt-2 pb-5">
                    <button (click)="new()">No</button>
                    <button type="submit" (click)="already()">Yes</button>
                </div>

                <button type="submit" id="back" (click)="closeModal()">Back</button>
                <button hidden id="CloseModal" data-dismiss="modal"></button>

            </div>
            <br>
            <br>
        </div>
    </div>
</div>