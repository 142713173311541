import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { DataproviderService } from '../services/dataprovider.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-patientdetail',
  templateUrl: './patientdetail.component.html',
  styleUrls: ['./patientdetail.component.css']
})
export class PatientdetailComponent implements OnInit {
  // Appoinment  = this.navCtrl.get('Appoinment');
  // Reason1     = this.navCtrl.get('reason');
  // doctorName  = this.navCtrl.get('doctorName');

  doctorName  : any;

  form: any = {
    surname: '',
    firstName: '',
    dob: '',
    medicareNumber: '',
    sequenceNumber: '',
  };

  constructor(
    private router: Router,
    private service:DataproviderService,
    private navCtrl: NgxNavigationWithDataComponent,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    var obj = {a: true, b: true, c: true, d: false}
    localStorage.setItem('timeline', JSON.stringify(obj) );

    // if (this.Appoinment == undefined) {
    //   this.router.navigate(['/firstPage']);
    // }
  }


  onSubmit(){
    if (
    this.form.surname == '' ||
    this.form.firstName == '' ||
    this.form.dob == '' ||
    this.form.medicareNumber == '' ||
    this.form.sequenceNumber == ''
    ) {

      this.toastFun("Please fill all fields" , 'Oops');

    } else {

      this.service.serachPatientData(this.form).subscribe((res: any)=>{
        console.log(res);

        if (res.statusCode == true) {
          this.toastFun("Patient not found" , 'Oops');
        }else {

          var PatientName = res[0].title +' '+ res[0].firstname +' '+ res[0].surname;

          localStorage.setItem('patientName' , PatientName );
          localStorage.setItem('page' ,'exist' );
          localStorage.setItem('patientid' ,res[0].internalid);
          this.navCtrl.navigate('/confirmappoinment');

          // this.navCtrl.navigate('/confirmappoinment', {
          //   Appoinment: this.Appoinment,
          //   reason: this.Reason1,
          //   patientName: PatientName,
          //   dcName: this.doctorName,
          //   page: 'exist'
          // });

        }
      });

    }
  }


  toastFun (title,header) {
    this.toastr.error(title,header, {
      timeOut: 2000,
      positionClass: 'toast-bottom-left',
      progressBar: true,
      progressAnimation: 'increasing'
    });
  }

}
