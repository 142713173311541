import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-makingappointment',
  templateUrl: './makingappointment.component.html',
  styleUrls: ['./makingappointment.component.css']
})
export class MakingappointmentComponent implements OnInit {

  TimeLine: any;
  constructor(private router:Router,private Active:ActivatedRoute) { }
  linkClass = false;

  ngOnInit(): void {
    this.TimeLine = JSON.parse(localStorage.getItem('timeline'));
  }

}
