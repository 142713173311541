<app-header></app-header>
<div class="main">
    <div class="container">

        <div class="row pt-5 first_Row">
            <div class="card second_card">
                <h4 class="card-title">Search Appoinments</h4>

                <hr id="hr1">

                <div class="formHeading">
                    <span id="req">*</span> <i> Required information to proceed</i>
                </div>

                <div class="card-body existPatient_CardBody">

                    <div class="row justify-content-center text-center">
                        <div class="col-12 col-md-12 col-lg-6">
                            <form [formGroup]='formGroup'>

                                <div class="row mb-2">
                                    <div class="col-md-3 p-0">
                                        <label for="">Surname: <span>*</span></label>
                                    </div>
                                    <div class="col-md-9">
                                        <input class="form-control" formControlName='surname' type="text" placeholder="Enter surname...">
                                    </div>
                                </div>

                                <div class="row mb-2">
                                    <div class="col-md-3 p-0">
                                        <label for="">First name: <span>*</span></label>
                                    </div>
                                    <div class="col-md-9">
                                        <input class="form-control" formControlName='firstName' type="text" placeholder="Enter Lastname...">
                                    </div>
                                </div>

                                <div class="row mb-2">
                                    <div class="col-md-3 p-0">
                                        <label for="">Date Of Birth: <span>*</span></label>
                                    </div>
                                    <div class="col-md-9">
                                        <input class="form-control" formControlName='dob' type="date">
                                    </div>
                                </div>


                                <div class="row mb-2">
                                    <div class="col-md-3 p-0">
                                        <label for="">Medicare No: <span>*</span></label>
                                    </div>
                                    <div class="col-md-9">
                                        <input class="form-control" formControlName='medicareNumber' type="text" placeholder="Madicare No">
                                    </div>
                                </div>

                                <div class="row mb-2">
                                    <div class="col-md-3 p-0">
                                        <label for="" class="seqNo">Seq No: <span>*</span></label>
                                    </div>
                                    <div class="col-md-9">
                                        <input class="form-control" formControlName='sequenceNumber' type="text" placeholder="Line No">
                                    </div>
                                </div>

                            </form>

                        </div>
                    </div>

                    <div class="row mt-5 mb-5 text-center justify-content-center">
                        <button [routerLink]="['/firstPage']" class="CustomeBtn mr-5">Back</button>
                        <button type="submit" (click)="onSubmit()" class="CustomeBtn">Next</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <br>
    <br>
</div>

<div class="footer">
    <p>Site powered by Meditco trading as Ozbiztech Pty Ltd</p>
</div>