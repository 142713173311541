import { Component, OnInit } from '@angular/core';
import { DataproviderService } from '../services/dataprovider.service';
import {  FormGroup,FormBuilder,Validators }   from '@angular/forms';
import { Router } from '@angular/router';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-newpatientform',
  templateUrl: './newpatientform.component.html',
  styleUrls: ['./newpatientform.component.css']
})
export class NewpatientformComponent implements OnInit {

  // Appoinment       = this.navCtrl.get('Appoinment');
  // reason           = this.navCtrl.get('reason');
  // doctorName       = this.navCtrl.get('doctorName');
  Pentions:any;

  constructor(
    public service:DataproviderService,
    public fb:FormBuilder,
    public router:Router,
    public navCtrl: NgxNavigationWithDataComponent,
    private datepipe: DatePipe,
    private toastr: ToastrService
    ) { }

  ngOnInit(): void {
    this.getGenders();
    this.initalizePatientForm();
    this.getTitle();
    this.getDVA();
    this.getPenttionTYpes();

    var obj = {a: true, b: true, c: true, d: false}
    localStorage.setItem('timeline', JSON.stringify(obj) );

    // if (this.Appoinment == undefined) {
    //   this.router.navigate(['/firstPage']);
    // }

  }
  public DVACardStatus
  public pensionCardStatus
  public sexType:any;
  public patientForm:FormGroup;
  public titles:any;
  public DVAData;

  getGenders = () => {
    this.service.getGenders().subscribe((res)=>{
      this.sexType = res
      console.log(this.sexType)
    })
  }

  initalizePatientForm = () =>{
    this.patientForm = this.fb.group({
      titleCode: ['',Validators.required],
      firstName: ['',Validators.required],
      middlename: ['test',Validators.required],
      dob: ['',Validators.required],
      sexCode: [Number,Validators.required],
      surname: ['',Validators.required],
      preferredname: ['',Validators.required],
      address1: ['',Validators.required],
      address2: ['test',Validators.required],
      city : ['test',Validators.required],
      postcode:['',Validators.required],
      postalAddress: ['test',Validators.required],
      postalCity: ['test',Validators.required],
      postalPostcode: ['',Validators.required],
      homePhone: ['',Validators.required],
      workPhone: ['',Validators.required],
      mobilePhone: ['',Validators.required],
      medicareNo: ['',Validators.required],
      medicareLineNo: ['',Validators.required],
      medicareExpiry: ['',Validators.required],
      pensionCode: [Number,Validators.required],
      pensionNo: ['',Validators.required],
      pensionExpiry: ['2021-03-09T10:59:56.945Z',Validators.required],
      dvaCode: ['',Validators.required],
      dvaNo: ['',Validators.required],
      recordNo: ['143',Validators.required],
      externalID: ['1234',Validators.required],
      email: ['',Validators.required]
    })
  }

  submit = () => {
    var d = this.patientForm.value.medicareExpiry.split('/');
    var d1 = d[0];
    var d2 = d[1];
    var date = d1+d2;
    this.service.appoimentObj_1.addPatientModel = this.patientForm.value;
    var user = {
      address1: this.patientForm.value.address1,
        address2: this.patientForm.value.address2,
        city: this.patientForm.value.city,
        dob: this.datepipe.transform(this.patientForm.value.dob , 'yyyy-MM-dd'),
        dvaCode: 2,
        dvaNo: this.patientForm.value.dvaNo,
        externalID: this.patientForm.value.externalID,
        email: this.patientForm.value.email,
        ethnicCode: 2,
        firstName: this.patientForm.value.firstName,
        homePhone: JSON.stringify(this.patientForm.value.homePhone),
        mobilePhone: JSON.stringify(this.patientForm.value.mobilePhone),
        medicareNo: this.patientForm.value.medicareNo,
        medicareLineNo: this.patientForm.value.medicareLineNo,
        medicareExpiry:  date,
        middlename:  this.patientForm.value.middlename,
        preferredname:  this.patientForm.value.preferredname,
        postcode:  this.patientForm.value.postcode,
        postalAddress: this.patientForm.value.postalAddress,
        postalCity:  this.patientForm.value.postalCity,
        postalPostcode: this.patientForm.value.postalPostcode,
        pensionCode: this.patientForm.value.pensionCode,
        pensionNo: this.patientForm.value.pensionNo,
        pensionExpiry: this.datepipe.transform(this.patientForm.value.pensionExpiry , 'yyyy-MM-dd'),
        recordNo: this.patientForm.value.recordNo,
        surname: this.patientForm.value.surname,
        titleCode: this.patientForm.value.titleCode,
        sexCode: this.patientForm.value.sexCode,
        workPhone: this.patientForm.value.workPhone
      }

    if (
      user.titleCode == '' ||
      user.surname == ''||
      user.firstName == ''||
      user.dob == ''||
      user.medicareNo == ''||
      user.medicareExpiry == ''||
      user.medicareLineNo == ''||
      user.address1 == ''||
      user.postcode == ''||
      user.email == ''||
      user.homePhone == ''||
      user.mobilePhone == ''
      ) {
        this.toastr.success('Please fill required fields', '', {
          timeOut: 4000,
          positionClass: 'toast-bottom-left',
          progressBar: true,
          progressAnimation: 'increasing'
        });
    } else {
      var PatientName = this.patientForm.value.firstName + this.patientForm.value.surname;

    localStorage.setItem('patientName' , PatientName);
    localStorage.setItem('patient' ,JSON.stringify(user));
    localStorage.setItem('page' ,'new');
    console.log(user)
    this.navCtrl.navigate('/confirmappoinment');
    }
  }

  getTitle = () =>{
    this.service.getTitle().subscribe((res)=>{
    this.titles = res
    })
  }

  getDVA = () => {
    this.service.getDVATypes().subscribe((res)=>{
      this.DVAData = res
    })
  }

  DvaStatus(a){
    this.DVACardStatus = a
  }

  getPenttionTYpes = () => {
    this.service.getPentionType().subscribe((res)=>{
      this.Pentions = res;
    })
  }
}
