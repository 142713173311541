<app-header></app-header>
<div class="main">
    <div class="container">

        <!--Time Line Row # 1-->
        <div class="row first_Row">
            <div class="card first_card">
                <div class="card-body">
                    <app-makingappointment></app-makingappointment>
                </div>
            </div>
        </div>

        <!--Form with Inputs Row # 2-->
        <div class="row pt-4 second_Row" *ngIf="loader">
            <div class="card second_card">
                <div class="card-body text-center justify-content-center">
                    <p class="text-center">Please wait</p>
                </div>
            </div>
        </div>

        <!--Form with Inputs Row # 2-->
        <div class="row pt-4 second_Row" *ngIf="!loader">
            <div class="card second_card">
                <h4 class="card-title">Select Reason For Appoinment</h4>
                <hr id="hr1">
                <div class="card-body">
                    <form class="consultant_Reason_Form">
                        <div class="row consultant">
                            <!--Standard Consult-->
                            <div class="form-group" *ngFor="let item of responce;let i = index">
                                <input type="radio" name="r1" id="{{i}}" (click)="onSelectChoice(item)">
                                <label for="{{i}}">{{item?.description}}</label>
                            </div>

                        </div>
                    </form>

                    <div class="row mb-5 mt-5 text-center justify-content-center">
                        <div class="col-md-12">
                            <button [routerLink]="['/firstPage']" class="CustomeBtn mr-5 mb-2">Back</button>
                            <button type="submit" (click)="toTimeSlot()" class="CustomeBtn">Next</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
    <br><br><br><br>
</div>
<div class="footer">
    <p>Site powered by Meditco trading as Ozbiztech Pty Ltd</p>
</div>