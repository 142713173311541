import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { ToastrService } from 'ngx-toastr';
import { zip } from 'rxjs';
import { DataproviderService } from '../services/dataprovider.service';
import { DatePipe, Location } from '@angular/common'

  @Component({
    selector: 'app-confirmappointment',
    templateUrl: './confirmappointment.component.html',
    styleUrls: ['./confirmappointment.component.css']
  })
  export class ConfirmappointmentComponent implements OnInit {

    // AppoinmentObj = this.navCtrl.get('Appoinment');
    // patientObj    = this.navCtrl.get('patient');
    // patientName   = this.navCtrl.get('patientName');
    // dcName        = this.navCtrl.get('dcName');
    // reas          = this.navCtrl.get('reason');
    // page          = this.navCtrl.get('page');

    AppoinmentObj : any;
    patientObj    : any;
    patientName   : any;
    dcName        : any;
    reas          : any;
    page          : any;
    patientid     : any;
    DateAppoinment: any;

    /*Dummy Value's*/

    pName      = 'Alexendra Paul';
    reasonofApp = 'Long Consultant';
    doctorName  = 'Dr. John Smith';
    surgeryMsg  : string = "If you develop a fever or flu, Please call us prior to arrival and advise reception you symptoms. Please check your details above and click confirm appointment button below to proceed.";

    constructor(
      private Activate:ActivatedRoute,
      private service:DataproviderService,
      private router:Router,
      public navCtrl: NgxNavigationWithDataComponent,
      private toastr: ToastrService,
      private location: Location
      ) { }



    ngOnInit(): void {

      this.AppoinmentObj = JSON.parse(localStorage.getItem('Appoinment'));
      this.patientObj    =  localStorage.getItem('patient');
      this.patientName   =  localStorage.getItem('patientName');
      this.dcName        =  localStorage.getItem('doctorName');
      this.reas          =  localStorage.getItem('reason');
      this.page          =  localStorage.getItem('page');
      this.patientid     =  localStorage.getItem('patientid');
      console.log(this.AppoinmentObj)
      if(this.AppoinmentObj != null || this.AppoinmentObj != undefined) {
        this.DateAppoinment = this.AppoinmentObj.appointmentDate;
      }

      var obj = {a: true, b: true, c: true, d: true}
      localStorage.setItem('timeline', JSON.stringify(obj) );
    }

    AddAppoiment(){
      if (this.page == 'new') {
      var data = {addAppointmentModel: this.AppoinmentObj , addPatientModel: JSON.parse(this.patientObj)};
      console.log(data)
      this.service.addPatientAppointment(data).subscribe((res:any)=>{
        console.log(res)
        if(res.status=="Appointment registered"){
          this.navCtrl.navigate('/SuccessPage', {data: data});
          this.toastFun('Appoinment fixed successfully');
        }
      })
      } else if(this.page == 'exist') {
        var obj = this.AppoinmentObj;
        console.log(obj);
        var ExistPatient = {
            "appointmentDate": obj.appointmentDate,
            "appointmentTime": obj.appointmentTime,
            "appointmentLenght": obj.appointmentLenght,
            "practitionerID": obj.practitionerID,
            "patientID": this.patientid,
            "loginID": 0,
            "locationId": 0,
            "videoUrl": "string"
        }
      this.service.addExistingPatientAppointment(ExistPatient).subscribe((res:any)=>{
        console.log(res)
        if(res.status=="Appointment registered"){
          this.navCtrl.navigate('/SuccessPage', {data: data});
          this.toastFun('Appoinment fixed successfully');
        }
      })
      }
    }

    Back() {
      localStorage.clear();
      this.backToBack();
    }

    toastFun (title) {
      this.toastr.success(title, '', {
        timeOut: 4000,
        positionClass: 'toast-top-right',
        progressBar: true,
        progressAnimation: 'increasing'
      });
    }


    backToBack() {
      this.location.back();
    }

  }
