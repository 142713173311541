<app-header></app-header>
<div class="main">
    <div class="container-fluid">
        <div class="container p-5">

            <!--Time Line Row # 1-->
            <div class="row pt-2 first_Row">
                <div class="card first_card">
                    <div class="card-body">
                        <app-makingappointment></app-makingappointment>
                    </div>
                </div>
            </div>

            <!--Form with Inputs Row # 2-->
            <!--Time Line Row # 1-->
            <div class="row pt-5 first_Row">
                <div class="card second_card">
                    <h4 class="card-title">Existing Patient Details</h4>

                    <hr id="hr1">

                    <div class="formHeading">
                        <span id="req">*</span> <i> Required information to proceed</i>
                    </div>

                    <div class="card-body existPatient_CardBody">

                        <div class="row">
                            <div class="col-12 col-md-10 col-lg-8">
                                <form #refVariable='ngForm' (ngSubmit)="onSubmit()">


                                    <div class="row">
                                        <div class="col-md-3 p-0">
                                            <label for="">Surname: <span id="req">*</span></label>
                                        </div>
                                        <div class="col-md-9">
                                            <input type="text" class="form-control" name="surname" [(ngModel)]="form.surname" #sname='ngModel' required minlength='3' placeholder="Enter Surname...">
                                            <small *ngIf='sname.invalid && (sname.touched || sname.dirty) ' class="text-danger">Please fill this field correctly! </small>
                                        </div>
                                    </div>

                                    <br>

                                    <div class="row">
                                        <div class="col-md-3 p-0">
                                            <label for="">First name: <span id="req">*</span></label>
                                        </div>
                                        <div class="col-md-9">
                                            <input type="text" class="form-control" [(ngModel)]="form.firstName" name="firstName" #fname='ngModel' required minlength='3' placeholder="Enter first name...">
                                            <small *ngIf='fname.invalid && (fname.touched || fname.dirty) ' class="text-danger">Please fill this field correctly! </small>
                                        </div>
                                    </div>

                                    <br>


                                    <div class="row">
                                        <div class="col-md-3 p-0">
                                            <label for="">Date Of Birth: <span id="req">*</span></label>
                                        </div>
                                        <div class="col-md-9">
                                            <input type="date" class="form-control" [(ngModel)]="form.dob" name="dob" ngModel #dob='ngModel' required>
                                            <small *ngIf='dob.invalid && (dob.touched || dob.dirty) ' class="text-danger">Please fill this field correctly! </small>
                                        </div>
                                    </div>


                                    <br>

                                    <div class="row">
                                        <div class="col-md-3 p-0">
                                            <label for="medino">Medicare No: <span id="req">*</span></label>
                                        </div>
                                        <div class="col-md-9">
                                            <input type="text" id="medi" class="form-control" [(ngModel)]="form.medicareNumber" name="medicareNumber" ngModel #medi='ngModel' required placeholder="Medicare No...">
                                            <small *ngIf='medi.invalid && (medi.touched || medi.dirty)' class="text-danger">Medicare is required!</small>
                                        </div>
                                    </div>

                                    <br>

                                    <div class="row">
                                        <div class="col-md-3 p-0">
                                            <label for="seqno" class="seqNo">Seq No: <span id="req">*</span></label>
                                        </div>
                                        <div class="col-md-9">
                                            <input type="text" id="seqno" class="form-control" [(ngModel)]="form.sequenceNumber" name="sequenceNumber" ngModel #line='ngModel' required placeholder="Line No">
                                            <small *ngIf='line.invalid && (line.touched || line.dirty) ' class="text-danger">Line No is required!</small>
                                        </div>
                                    </div>

                                    <br><br><br>

                                </form>

                            </div>
                        </div>

                        <div class="row mb-5 text-center justify-content-center">
                            <div class="col-md-12">
                                <button [routerLink]="['/timeslot']" class="CustomeBtn mr-5 mb-2">Back</button>
                                <button type="submit" (click)="onSubmit()" class="CustomeBtn">Next</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </div>
    </div>
    <br>
    <br>
</div>

<div class="footer">
    <p>Site powered by Meditco trading as Ozbiztech Pty Ltd</p>
</div>