<app-header></app-header>
<div class="main">
    <div class="container-fluid">
        <div class="container">

            <!--Detail-->
            <div class="row justify-content-center">
                <div class="col-12 col-md-12 col-lg-10">
                    <div class="card second_Card ">
                        <h4 class="card-title pt-3 pl-3 pb-0">Appoinment Confirmation</h4>
                        <hr>
                        <div class="card-body">
                            <div *ngIf="UserAppoinments?.length > 1">
                                <div class="appoinments" *ngFor="let appoiment of UserAppoinments">
                                    <div class="row">
                                        <div class="col-10 col-md-11 col-lg-11">
                                            <p>{{time(appoiment.appointmentTime,appoiment.appointmentDate)}}</p>
                                        </div>
                                        <div class="col-2 col-md-1 col-lg-1">
                                            <i class="fa fa-trash-o" aria-hidden="true" (click)="cencalAppoiment(appoiment?.recordID)"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="UserAppoinments?.length < 1">
                                <div class="appoinments">
                                    <div class="row text-center justify-content-center">
                                        <p>No Appoinments founds</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <br><br>
        <br><br>
    </div>
</div>

<div class="footer">
    <p>Site powered by Meditco trading as Ozbiztech Pty Ltd</p>
</div>