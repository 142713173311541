import { Component, OnInit } from '@angular/core';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { DataproviderService } from '../services/dataprovider.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-availale-dc',
  templateUrl: './availale-dc.component.html',
  styleUrls: ['./availale-dc.component.css']
})
export class AvailaleDCComponent implements OnInit {
  todayDate = this.datepipe.transform(Date.now() , 'yyyy-MM-dd');
  DC: any;
  DoctorName: any;
  AppObj: any;
  loader = false;

  constructor(
    public navCtrl: NgxNavigationWithDataComponent,
    private dataservice: DataproviderService,
    private router: Router,
    public datepipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.loader = true;
    this.dataservice.AvailableConsultant(localStorage.getItem('id')).subscribe((res:any)=>{
      this.DC = res;
    this.loader = false;
    });

    var obj = {a: true, b: true, c: false, d: false}
      localStorage.setItem('timeline', JSON.stringify(obj) );
  }

  GetSlot(AppoinmentDate,DCId,dcName,appValue) {
      this.AppObj =  {
        appointmentDate: this.datepipe.transform(AppoinmentDate , 'yyyy-MM-dd'),
        appointmentTime: appValue.appointmentTime,
        appointmentLenght: appValue.appointmentLength,
        practitionerID: DCId,
        patientID: 0,
        loginID: 0,
        locationId: 0,
        videoUrl: "string"
      };
      this.DoctorName = dcName;
    localStorage.setItem('Appoinment' , JSON.stringify(this.AppObj));
    localStorage.setItem('doctorName' , this.DoctorName);
    document.getElementById('OpenModal').click();
  }


  new() {
    this.navCtrl.navigate('/NewPatient');
    document.getElementById('CloseModal').click();
  }

  already() {
    this.navCtrl.navigate('/patientdetail');
    document.getElementById('CloseModal').click();
  }

  closeModal() {
    document.getElementById('CloseModal').click();
    this.router.navigate(['/firstPage']);
  }

  secondsTodate(seconds) {
  var minutes: any = Math.floor(seconds / 60);
  var hours: number = Math.floor(minutes / 60);
  minutes = Math.floor(minutes % 60);
  var mins: string = "0";
  if (minutes == 0) {
    minutes = minutes + "" + mins;
  }
  var time = 'AM'
  if (hours >= 12) {
    hours = hours - 12;
    time = 'PM'
  }
  return hours + ":" + minutes + " " + time;
  };

  goCalender(item) {
    localStorage.setItem('DoctorId', item.userid);
    this.router.navigate(['/selectTime']);
  }

}
