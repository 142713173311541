import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { DataproviderService } from '../services/dataprovider.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-times',
  templateUrl: './select-times.component.html',
  styleUrls: ['./select-times.component.css']
})
export class SelectTimesComponent implements OnInit {
  AllSlots: any;
  DoctorId: any;
  todayDate = this.datepipe.transform(Date.now() , 'yyyy-MM-dd');
  AppObj: any;
  ModelDate: any;
  resp: any;
  constructor(
    public navCtrl: NgxNavigationWithDataComponent,
    private dataservice: DataproviderService,
    private datepipe: DatePipe,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.DoctorId  = localStorage.getItem('DoctorId');
    var data = {
      consultantID: this.DoctorId,
      sessionDate: this.todayDate
    }
    this.dataservice.getGetConsutantAvailableTimeslotByDate(data).subscribe((res:any)=>{
      console.log(res)
      this.resp = res;
      this.AllSlots = res[0].freeAppointments[0].dailyAppointments;
      console.log(this.AllSlots)
    });

  var obj = {a: true, b: true, c: false, d: false}
    localStorage.setItem('timeline', JSON.stringify(obj) );
  }


  secondsTodate(seconds) {
    var minutes: any = Math.floor(seconds / 60);
    var hours: number = Math.floor(minutes / 60);
    minutes = Math.floor(minutes % 60);
    var mins: string = "0";
    if (minutes == 0) {
      minutes = minutes + "" + mins;
    }
    var time = 'AM'
    if (hours >= 12) {
      hours = hours - 12;
      time = 'PM'
    }
    return hours + ":" + minutes + " " + time;
  };

  changeDate(val) {
      let day: any;
      let month: any;
        let year = val.year;
        if (val.day < 10) {
          day='0' + val.day;
        } else {
        day= val.day;
      } if (val.month < 10) {
        month='0' + val.month;
      }else {
        month= val.month;
      }
    let merge= year+ '-'+month+ '-'+day;
    let date= this.datepipe.transform(merge , 'yyyy-MM-dd');
    this.ModelDate = date;
    console.log(this.ModelDate)
    var data={ consultantID: this.DoctorId, sessionDate: date }
    console.log(data);
    this.dataservice.getGetConsutantAvailableTimeslotByDate(data).subscribe((res:any)=>{
    console.log(res);
      this.AllSlots = res[0].freeAppointments[0].dailyAppointments;
    });
  }

  GetSlot(date,userid,firstname,val) {
    console.log(date,userid,firstname,val)
    console.log(this.ModelDate)
    var data1 =  {
      appointmentDate: this.datepipe.transform(this.ModelDate , 'yyyy-MM-dd'),
      appointmentTime: val.appointmentTime,
      appointmentLenght: val.appointmentLength,
      practitionerID: this.resp[0].userid,
      patientID: 0,
      loginID: 0,
      locationId: 0,
      videoUrl: "string"
    }
    localStorage.setItem('doctorName' , this.resp[0].title+' '+this.resp[0].firstname);
    localStorage.setItem('Appoinment' , JSON.stringify(data1));
    document.getElementById('OpenModal').click();
}

new() {
  this.navCtrl.navigate('/NewPatient');
  document.getElementById('CloseModal').click();
}

already() {
  this.navCtrl.navigate('/patientdetail');
  document.getElementById('CloseModal').click();
}

closeModal() {
  document.getElementById('CloseModal').click();
  this.router.navigate(['/firstPage']);
}
}
