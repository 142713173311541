import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { DataproviderService } from '../services/dataprovider.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-checkedin',
  templateUrl: './checkedin.component.html',
  styleUrls: ['./checkedin.component.css']
})
export class CheckedinComponent implements OnInit {

  constructor(
    public service:DataproviderService,
    public fb:FormBuilder,
    public router:Router,
    private modalService: NgbModal,
    public navCtrl: NgxNavigationWithDataComponent,
    private toast: ToastrService
  ) { }

@ViewChild('appoimentModal',{static:false}) appoimentModal:any

  ngOnInit(): void {
    this.startForm();
  }

  public formGroup:FormGroup;
  public appoimentList:any;

  public startForm = ()=>{
    this.formGroup = this.fb.group({
      surname: ['',Validators.required],
      firstName: ['',Validators.required],
      dob: ['',Validators.required],
      medicareNumber: ['',Validators.required],
      sequenceNumber: ['',Validators.required]
    })
  }

  onSubmit(){
    let x = this.formGroup.value
    let date = x.dob;
    x.dob = new Date(date).toISOString().slice(0, 10)
      this.service.getappoiment(x).subscribe((res: any)=>{
        if (res == 'Patient not found') {
          this.toast.success('Patient not found', 'Oops', {
            timeOut: 3000,
            positionClass: 'toast-top-right',
            progressBar: true,
            progressAnimation: 'increasing'
          });
        } else if(res.length > 1) {
          localStorage.setItem('UserAppoinments_Data' , JSON.stringify(x));
          this.router.navigate(['/confirmcheckedin']);
        }
      })
    }

    time(value: number,date:any) {
      var minutes: any = Math.floor(value / 60);
      var hours: number = Math.floor(minutes / 60);
      minutes = Math.floor(minutes % 60);
      var mins: string = "0";
      if (minutes == 0) {
        minutes = minutes + "" + mins;
      }
      var time = 'AM'
      if (hours >= 12) {
        hours = hours - 12;
        time = 'PM'
      }
      let dayAsDate = new Date(date).toISOString().slice(0, 10);
      return dayAsDate + " " + hours + ":" + minutes + " " + time;
    }

}
