import { BrowserModule } from '@angular/platform-browser';
import {  NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule,ReactiveFormsModule } from '@angular/Forms';
import { HeaderComponent } from './header/header.component';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FirstpageComponent } from './firstpage/firstpage.component';
import { ReasonpageComponent } from './reasonpage/reasonpage.component';
import { SuccesspageComponent } from './successpage/successpage.component';
import { TimeslotpageComponent } from './timeslotpage/timeslotpage.component';
import { PatientdetailComponent } from './patientdetail/patientdetail.component';
import { ConfirmappointmentComponent } from './confirmappointment/confirmappointment.component';
import { CheckedinComponent } from './checkedin/checkedin.component';
import { ConfirmcheckedinComponent } from './confirmcheckedin/confirmcheckedin.component';
// import { CalendarModule } from '@syncfusion/ej2-angular-calendars';
import { MakingappointmentComponent } from './makingappointment/makingappointment.component';
import { NewpatientformComponent } from './newpatientform/newpatientform.component';
import { ScheduleModule, RecurrenceEditorModule, DayService, WeekService, MonthAgendaService, WorkWeekService, MonthService } from '@syncfusion/ej2-angular-schedule';
import { TimeslotpipePipe } from './timeslotpipe.pipe';
import { SeeDoctorComponent } from './see-doctor/see-doctor.component';
import { AvailaleDCComponent } from './availale-dc/availale-dc.component';
import { SelectTimesComponent } from './select-times/select-times.component';
import { CancelAppoinmentComponent } from './cancel-appoinment/cancel-appoinment.component';
import { NgxNavigationWithDataComponent } from "ngx-navigation-with-data";
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DatePipe } from "@angular/common";
import {DpDatePickerModule} from 'ng2-date-picker';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FirstpageComponent,
    ReasonpageComponent,
    SuccesspageComponent,
    TimeslotpageComponent,
    PatientdetailComponent,
    ConfirmappointmentComponent,
    CheckedinComponent,
    ConfirmcheckedinComponent,
    MakingappointmentComponent,
    NewpatientformComponent,
    TimeslotpipePipe,
    SeeDoctorComponent,
    AvailaleDCComponent,
    SelectTimesComponent,
    CancelAppoinmentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    BrowserAnimationsModule,
    // CalendarModule,
    AppRoutingModule,
    HttpClientModule,
    ScheduleModule,
    RecurrenceEditorModule,
    DpDatePickerModule
  ],
  providers: [
    DayService,
    WeekService,
    MonthAgendaService,
    WorkWeekService,
    MonthService,
    NgxNavigationWithDataComponent,
    DatePipe,
    {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
