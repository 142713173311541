<app-header></app-header>
<div class="main" *ngIf="!loader">
    <div class="container-fluid">
        <div class="container">

            <!--Time Line Row # 1-->
            <div class="row first_Row">
                <div class="card first_card">
                    <div class="card-body">
                        <app-makingappointment></app-makingappointment>
                    </div>
                </div>
            </div>

            <!--Form with Inputs Row # 2-->
            <div class="row pt-5 second_Row">
                <div class="card second_card">

                    <h4 class="card-title">Available Doctors</h4>
                    <hr id="hr1">

                    <div class="card-body p-5">
                        <div class="row">
                            <!--Doctores-->
                            <div class="card DCProfile" *ngFor="let item of DC; let i= index">
                                <div class="card-body">
                                    <div class="row pl-3 pr-3">
                                        <div class="col-12 col-md-4 col-lg-3">
                                            <div class="dcImg">
                                                <img src="../../assets/images/Doctors1.png" alt="">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-12 col-lg-9">

                                            <h4 id="dcName">{{item?.title}} . {{item?.firstname}} {{item?.surname}} </h4>
                                            <b id="specialization">{{item?.groupname}}</b>

                                            <div class="row">
                                                <div class="col-12 col-md-5 col-lg-4">
                                                    <p id="label">First Available Slots: </p>
                                                </div>
                                                <div class="col-12 col-md-7 col-lg-8">
                                                    <div class="slot">
                                                        <p *ngIf="item?.freeAppointments[0]?.dailyAppointments?.length < 1"> No Available</p>
                                                        <!-- <p *ngIf="item?.freeAppointments[0]?.dailyAppointments?.length < 1 || item?.freeAppointments[1]?.dailyAppointments?.length >= 1" (click)="GetSlot(todayDate , item?.userid ,item?.firstname , item?.freeAppointments[1]?.dailyAppointments[0]?.appointmentTime)">{{ secondsTodate(item?.freeAppointments[1]?.dailyAppointments[0]?.appointmentTime) }}</p> -->
                                                        <p *ngIf="item?.freeAppointments[0]?.dailyAppointments?.length > 1" (click)="GetSlot(todayDate , item?.userid ,item?.firstname , item?.freeAppointments[0]?.dailyAppointments[0]?.appointmentTime)">{{ secondsTodate(item?.freeAppointments[0]?.dailyAppointments[0]?.appointmentTime) }}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row slotRow" *ngFor="let appoin of item?.freeAppointments ; let j =index">
                                                <div class="col-12 col-md-5 col-lg-4">
                                                    <span id="label">{{appoin?.dayOfWeek}}</span>
                                                    <span class="pl-2 labeldate">{{appoin?.dateOfDay | date: 'mediumDate'}}</span>
                                                </div>
                                                <div class="col-12 col-md-7 col-lg-8 pb-2">
                                                    <span *ngIf="appoin?.dailyAppointments?.length > 0">
                                                    <div class="slot" *ngFor="let slot of appoin?.dailyAppointments;let k =index" >
                                                      <p (click)="GetSlot(appoin?.dateOfDay , item?.userid , item?.firstname , slot)">{{secondsTodate(slot?.appointmentTime)}}</p>
                                                  </div>
                                                  </span>

                                                    <span *ngIf="appoin?.dailyAppointments?.length < 1">
                                                      <p>Today No slot Available</p>
                                                  </span>

                                                </div>
                                            </div>

                                            <div class="col-md-12">
                                                <p class="selTimePage">
                                                    <a (click)="goCalender(item)"> <i class="fa fa-calendar" aria-hidden="true"></i> See All times</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="bottomDiv my-5">
                            <button [routerLink]="['/Reason']" routerLinkActive="router-link-active">Back</button>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
    <br>
</div>
<div class="footer" *ngIf="!loader">
    <p>Site powered by Meditco trading as Ozbiztech Pty Ltd</p>
</div>



<!-- Button trigger modal -->
<button type="button" hidden id="OpenModal" data-toggle="modal" data-target="#CheckModal"></button>

<!-- Modal -->
<div class="modal fade" id="CheckModal" tabindex="-1" role="dialog" aria-labelledby="CheckModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="row justify-content-center text-center">
                <p class="pt-4">Have you see by the doctor in this surgey before ?</p>
                <div class="bottomDiv p-0 pt-2 pb-5">
                    <button (click)="new()">No</button>
                    <button type="submit" (click)="already()">Yes</button>
                </div>

                <button type="submit" id="back" (click)="closeModal()">Back</button>
                <button hidden id="CloseModal" data-dismiss="modal"></button>

            </div>
            <br>
            <br>
        </div>
    </div>
</div>