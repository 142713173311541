<app-header></app-header>
<div class="main">
    <div class="container-fluid">
        <div class="container">

            <!--Time Line Row # 1-->
            <div class="row  first_Row">
                <div class="card first_card">
                    <div class="card-body">
                        <app-makingappointment></app-makingappointment>
                    </div>
                </div>
            </div>

            <!--Form with Inputs Row # 2-->
            <div class="row pt-5 second_Row">
                <div class="card second_card">
                    <h4 class="card-title">Choose Timeslot</h4>
                    <hr id="hr1">
                    <div class="card-body">
                        <form>

                            <!--First Row-->
                            <div class="row">

                                <div class="col-12 col-md-12 col-lg-10 p-0">

                                    <div class="row">
                                        <!--Date-->
                                        <div class="col-md-4 p-0">
                                            <label for="date1" class="pt-2 pr-2">Choose Date: </label>
                                        </div>
                                        <div class="col-md-8 p-0">
                                            <input type="date" class="form-control" id="date1" name="date" (change)="getSlotsByDate()" [(ngModel)]="date">
                                            <!-- <i class="fa fa-calendar pl-1" aria-hidden="true"></i> -->
                                        </div>
                                    </div>

                                    <br>

                                    <div class="row" *ngIf="AllSlots?.length >= 1">
                                        <!--Date-->
                                        <div class="col-md-4 p-0">
                                            <label for="Timeslot" class="pt-2 pr-2">Choose Timeslot: </label>
                                        </div>
                                        <div class="col-md-8 p-0">
                                            <select name="slot" id="Timeslot" (change)="CheckDoctors()" [(ngModel)]="checkSlot" class="form-control" [(ngModel)]="getslot" style="min-width: 80%;">
                                              <option *ngFor="let item of AllSlots; let i = index" [selected]="i == 0" value="{{item.starttime}} , {{item.length}}" >{{ secondsTodate(item.starttime)}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <br>

                                    <div class="row p-0" *ngIf="AllDoctors?.length >= 1">
                                        <div class="col-md-4 p-0">
                                            <label for="chooseDC">Choose Doctors:</label>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="row">

                                                <div class="col-12 col-md-6 col-lg-6 p-1" *ngFor="let item of AllDoctors; let i = index">
                                                    <div class="card text-center DoctorCard" (click)="goClient(item)">
                                                        <div class="card-body">
                                                            <!--Doctor icon-->
                                                            <img src="../../assets/images/Doctors1.png" alt="" id="doctorIcon">
                                                            <!--Doctor Name-->
                                                            <h5 id="doctorName">{{item.title}}. {{item.firstname}}</h5>
                                                            <!--Doctor specialization-->
                                                            <i id="specialization">{{item?.groupname}}</i>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <br>

                            <!--Doctors Row-->
                            <!-- <div class="row justify-content-center text-center" *ngIf="AllDoctors?.length <= 0">
                                        <p class="text-center">No Doctors Founds</p>
                                    </div> -->


                        </form>

                        <div class="bottomDiv my-5">
                            <button [routerLink]="['/Reason']" routerLinkActive="router-link-active">Back</button>
                            <!-- <button type="submit" *ngIf="AllSlots?.length > 0">Total Slots: {{AllSlots?.length}} </button> -->
                        </div>

                    </div>
                </div>
            </div>


        </div>
    </div>
    <br><br>
</div>

<div class="footer">
    <p>Site powered by Meditco trading as Ozbiztech Pty Ltd</p>
</div>





<!-- Button trigger modal -->
<button type="button" hidden id="OpenModal" data-toggle="modal" data-target="#CheckModal"></button>

<!-- Modal -->
<div class="modal fade" id="CheckModal" tabindex="-1" role="dialog" aria-labelledby="CheckModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="row justify-content-center text-center">
                <p class="pt-4">Have you see by the doctor in this surgey before ?</p>
                <div class="bottomDiv p-0 pt-2 pb-5">
                    <button (click)="new()">No</button>
                    <button type="submit" (click)="already()">Yes</button>
                </div>

                <button type="submit" id="back" (click)="closeModal()">Back</button>
                <button hidden id="CloseModal" data-dismiss="modal"></button>

            </div>
            <br>
            <br>
        </div>
    </div>
</div>