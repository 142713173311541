<img class="site-logo" src="/assets/images/1.png">
<div class="main">
    <img class="background" src="/assets/images/homepage_bg.jpg">
    <div class="container">
        <div id="main-col" class="col-md-8">
            <div class="card-body">

                <h4 id="tit">MAKE YOUR APPOINTMENT BY FOLLOWING THE 4 EASY STEPS</h4>
                <ul>
                    <li><span class="count"> 1 </span> Select Reason of Appointment</li>
                    <li><span class="count"> 2 </span> Choose your desired/available timeslot</li>
                    <li><span class="count"> 3 </span> Provide your details</li>
                    <li><span class="count"> 4 </span> Confirm the appointment</li>
                </ul>
                <div class="mt-4 contact">
                    For any issues with booking please contact us on <span>02 9773 3676</span>
                    <br> or send an email to <span> reception@easthillsmedical.com.au.</span> <br>
                    <button id="choose-option" class="btn btn-primary">CHOOSE ANY OPTION</button>
                </div>

             
                <span style="margin-left: -20px;">
                    <img class="btn-img" (click)="avaiable()"  src="/assets/images/text_bag3.png">
                    <img class="btn-img" id="btn2" (click)="particular()"   src="/assets/images/text_bag1.png">
                    <img class="btn-img" id="btn3" [routerLink]="['/checkedin']"  src="/assets/images/text_bag2.png">
                </span>
            </div>

        </div>

    </div>
    <br>
    <br>
</div>
<div class="footer">
    <p>Site powered by Meditco trading as Ozbiztech Pty Ltd</p>
</div>