import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { DoctorTimeSlots } from '../model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataproviderService {

  // baseUrl = 'http://60.241.253.11:8080/api/'
  // baseUrl = 'http://27.33.253.32:8080/api/'
    baseUrl = 'http://120.151.88.189:8080/api/'

  proxyUrl = 'https://cors-anywhere.herokuapp.com/'
  appointmentTypeUrl = this.baseUrl + 'AppointmentType';
  constructor(private http : HttpClient) {

  }

  getSlotsBydate = (data: any):Observable<any> =>  {
    return this.http.post(this.baseUrl+'Session/GetAvailableTimeSlotsByDate', data);
  }

  GetDoctorBySlot = (data: any):Observable<any> =>  {
    return this.http.post(this.baseUrl+'Consultant/GetConsultantsByDateAndTimeSlot', data);
  }

  AvailableConsultant = (AppoinmentId: any):Observable<any> =>  {
    return this.http.get(this.baseUrl+'Consultant/'+AppoinmentId);
  }

  GetConsultantAvailableTimeslots =  (id: any):Observable<any> =>  {
    return this.http.get(this.baseUrl+'Consultant/GetConsultantAvailableTimeslots'+id);
  }

  getGetConsutantAvailableTimeslotByDate = (body) => {
    return this.http.post<any[]>(this.baseUrl+'Consultant/GetConsutantAvailableTimeslotByDate',body)
  }

  onfetchReason =  ():Observable<any> =>  {
    return this.http.get(this.baseUrl+'AppointmentType');
  }

  onfetchTimeSlots():Observable<any[]>{
    return this.http.get<any>(this.baseUrl+'Consultant/1');
  }

  postPatientData(postData){
    return this.http.post<any>(this.baseUrl + 'Appointment/ExistingPatientAppointment', postData);
  }

  serachPatientData = (body):Observable<any> => {
    return this.http.post<any>(this.baseUrl+'Patient/SearchPatient',body)
  }
  addPatientAppointment = (data):Observable<any> =>{
    return this.http.post<any>( this.baseUrl+'Appointment/NewPatientAppointment',data)
  }

  addExistingPatientAppointment = (data):Observable<any> =>{
    return this.http.post<any>( this.baseUrl+'Appointment/ExistingPatientAppointment',data)
  }

  getGenders = ():Observable<any[]> => {
    return this.http.get<any>(this.baseUrl+'Gender');
  }

  getTitle = ():Observable<any[]> =>{
    return this.http.get<any>(this.baseUrl+'Title')
  }
  getDVATypes = ():Observable<any[]> =>{
    return this.http.get<any>(this.baseUrl+'DVAType')
  }

  getPentionType = ():Observable<any[]> => {
    return this.http.get<any>(this.baseUrl+'PensionType')
  }

  getappoiment= (body):Observable<any[]> => {
    return this.http.post<any[]>(this.baseUrl+'Appointment/GetPatientAppointments',body)
  }

  appoimentObj_1:any = {};
  Doctor:any;
  objForCencal:any;

  cancelappoiment = (id):Observable<any[]> =>{
    let x = {
      appointmentId:id
  }

  return this.http.post<any>(this.baseUrl+`Appointment/CancelAppointment${id}`,{x})

  }



}

