import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { DataproviderService } from '../services/dataprovider.service';
import Swal from 'sweetalert2'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-confirmcheckedin',
  templateUrl: './confirmcheckedin.component.html',
  styleUrls: ['./confirmcheckedin.component.css']
})
export class ConfirmcheckedinComponent implements OnInit {
  UserAppoinments: any;
  constructor(
    public service:DataproviderService,
    public ActivatedRouter:ActivatedRoute,
    public Router:Router,
    public modalService: NgbModal,
    public navCtrl: NgxNavigationWithDataComponent,
    private toast: ToastrService
    ) { }

  ngOnInit(): void {
    this.getAppoinments();
  }

  time(value: number,date:any) {
    var minutes: any = Math.floor(value / 60);
    var hours: number = Math.floor(minutes / 60);
    minutes = Math.floor(minutes % 60);
    var mins: string = "0";
    if (minutes == 0) {
      minutes = minutes + "" + mins;
    }
    var time = 'AM'
    if (hours >= 12) {
      hours = hours - 12;
      time = 'PM'
    }
    let dayAsDate = new Date(date).toISOString().slice(0, 10);
    return dayAsDate + " " + hours + ":" + minutes + " " + time;
  }

  public cencalAppoiment = (id) =>{
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.service.cancelappoiment(id).subscribe((res:any)=>{
            this.getAppoinments();
            if (res == true) {
              this.toast.success('Appoinment Cancel successfully', '', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
                progressBar: true,
                progressAnimation: 'increasing'
              });
            } else {

            }
          })
        }
      })
  }

  getAppoinments() {
    let data = JSON.parse(localStorage.getItem('UserAppoinments_Data'));
    this.service.getappoiment(data).subscribe((res: any)=>{
      this.UserAppoinments = res;
    })
  }

}
