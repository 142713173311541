<div class="main">
    <div class="container-fluid">
        <div class="container p-5">

            <!--Form with Inputs Row # 2-->
            <div class="row pt-5 second_Row justify-content-center">
                <div class="col-12 col-sm-12 col-md-8 col-lg-6 mt-5">
                    <div class="card second_card mt-5">
                        <div class="card-body text-center">
                            <p class="pt-4">Have you see by the doctor in this surgey before ?</p>
                            <div class="bottomDiv p-0 pt-2 pb-5">
                                <button (click)="new()">No</button>
                                <button type="submit" (click)="already()">Yes</button>
                            </div>

                            <button type="submit" id="back" routerLink="/firstPage">Back</button>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>