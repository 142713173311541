<app-header>
</app-header>

<div class="main">
    <div class="container-fluid">
        <div class="container">

            <!--Detail Final-->
            <div class="row pt-5 justify-content-center">
                <div class="col-12 col-md-7 col-lg-6">
                    <div class="card second_Card">
                        <div class="card-body text-center">
                            <div class="patientDetail">
                                <div class="row">
                                    <div class="col-12  col-md-6 col-lg-4 firstcol ">
                                        <span id="span">Patient Name:</span>
                                    </div>
                                    <div class="col-12  col-md-6 col-lg-8  pinfo ">
                                        <!-- <span>{{patientObj.addPatientModel.firstName}}</span> -->
                                        <label>M Raza Abid</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12  col-md-6 col-lg-4 firstcol">
                                        <span id="span">Date:</span>
                                    </div>
                                    <div class="col-12  col-md-6 col-lg-8  pinfo">
                                        <!-- <span>{{patientObj.addAppointmentModel.appointmentDate}}</span> -->
                                        <label>20/03/2021</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12  col-md-6 col-lg-4  firstcol ">
                                        <span id="span">Reason of Appointment:</span>
                                    </div>
                                    <div class="col-12  col-md-6 col-lg-8  pinfo ">
                                        <!-- <span>{{reasonofApp}}</span> -->
                                        <label>I don't know</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12  col-md-6 col-lg-4  firstcol ">
                                        <span id="span">Doctor:</span>
                                    </div>
                                    <div class="col-12  col-md-6 col-lg-8  pinfo ">
                                        <!-- <span>{{Doctor.title +" "+Doctor.firstname}}</span> -->
                                        <label>M Imran Arshad</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-4 justify-content-center text-center">
                        <button class="btn OKBTN">Cancel Appoinment</button>
                    </div>

                </div>
            </div>

            <div class="row justify-content-center text-center">
                <div class="col-md-9">
                    <div class="container">
                        <button class="btn thanksId">Thanks you for visiting EHMC. Have a nice day</button>
                    </div>
                </div>
            </div>

        </div>
        <br><br>
    </div>
</div>

<div class="footer">
    <p>Site powered by Meditco trading as Ozbiztech Pty Ltd</p>
</div>