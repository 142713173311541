import { Component, OnInit } from '@angular/core';
import { DataproviderService } from '../services/dataprovider.service';

@Component({
  selector: 'app-successpage',
  templateUrl: './successpage.component.html',
  styleUrls: ['./successpage.component.css']
})
export class SuccesspageComponent implements OnInit {
  patientName:any;
  constructor() { }

  ngOnInit(): void {
    this.patientName   =  localStorage.getItem('patientName');
  }

}
