<app-header></app-header>
<div class="main">
    <div class="container-fluid">
        <div class="container p-5">

            <!--Time Line Row # 1-->
            <div class="row pt-5 first_Row">
                <div class="card first_card">
                    <div class="card-body">
                        <app-makingappointment></app-makingappointment>
                    </div>
                </div>
            </div>

            <!--Form with Inputs Row # 2-->
            <div class="row pt-5 second_Row">
                <div class="card second_card">
                    <h4 class="card-title">New Patient Details</h4>

                    <hr id="hr1">

                    <div class="formHeading pl-5">
                        <br>
                        <span id="req">*</span> <i> Required information to proceed</i>
                    </div>

                    <div class="card-body">

                        <div class="row justify-content-center text-center">
                            <div class="col-12 col-md-12  col-lg-8">
                                <form class=" form1 " [formGroup]='patientForm'>

                                    <div class="row mb-3">
                                        <div class="col-md-4 text-left ">
                                            <label for="">Title:<span>*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <select formControlName='titleCode' class="form-control">
                                        <option *ngFor='let title of titles' [value]='title.titlecode'>
                                            {{title. title}}
                                        </option>
                                    </select>
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-md-4 text-left ">
                                            <label for="">Surname:<span>*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <input formControlName='surname' type="text" name="" id="" class="form-control" placeholder="Enter surname...">
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-md-4 text-left ">
                                            <label for="">First name:<span>*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <input formControlName='firstName' type="text" name="" id="" class="form-control" placeholder="Enter First name...">
                                        </div>
                                    </div>


                                    <div class="row mb-3">
                                        <div class="col-md-4 text-left ">
                                            <label for=""> Date of Birth: <span>*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <input formControlName='dob' type="date" name="" id="" class="form-control" placeholder="DD / MM / YYYY">
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-md-4 text-left ">
                                            <label for=""> Gender (Select one)</label>
                                        </div>
                                        <div class="col-md-8">
                                            <select formControlName='sexCode' class="form-control">
                                          <option *ngFor='let data of sexType' [value]='data.sexcode'>
                                            {{data.sex}}
                                          </option>
                                        </select>
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-md-4 text-left ">
                                            <label for="">ATSI status: <span>*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <select class="form-control">
                                            <option  value='0'>Neither </option>
                                            <option  value='1'>Aboriginal </option>
                                            <option  value='3'>Torres Strait Islander </option>
                                            <option  value='4'>Both </option>
                                          </select>
                                            <!-- <input type="text" name="" id="" class="form-control" placeholder="Select one..."> -->
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-md-4 text-left ">
                                            <label for="">Medicare Number: <span>*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <input formControlName='medicareNo' type="text" name="" id="" class="form-control" placeholder="Medicare No...">
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-md-4 text-left ">
                                            <label for="">IRN: <span>*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <input formControlName='medicareLineNo' type="text" name="" id="" class="form-control" placeholder="IRN No...">
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-md-4 text-left ">
                                            <label for="">EXP: <span>*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <input formControlName='medicareExpiry' type="text" name="" id="" class="form-control" placeholder="MM/YYYY...">
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-md-4 text-left ">
                                            <label for="">Do you have pension card:</label>
                                        </div>
                                        <div class="col-md-8" style="text-align: left !important;">
                                            <input [(ngModel)]='pensionCardStatus' [ngModelOptions]="{standalone: true}" type="radio" id="pension card" name="pension card" value="Yes">
                                            <label for="pension card" class="pl-2 pr-5">Yes</label>
                                            <input [(ngModel)]='pensionCardStatus' [ngModelOptions]="{standalone: true}" type="radio" id="pension card" name="pension card" value="No">
                                            <label for="pension card" class="pl-2">No</label>
                                        </div>
                                    </div>

                                    <div class="row mb-3" *ngIf='pensionCardStatus=="Yes"'>
                                        <p *ngFor='let data of Pentions' id="radioLabel">
                                            <input formControlName='pensionCode' type="radio" id="DVA" name="pensionCode" [value]="data.pensioncode">
                                            <label for="DVA">{{data.pensiontype}}</label>
                                        </p>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-md-4 text-left ">
                                            <label for="">Pension/HCC:</label>
                                        </div>
                                        <div class="col-md-8">
                                            <input formControlName='pensionNo' type="text" name="" id="" class="form-control" placeholder="Pension card No..">
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-md-4 text-left ">
                                            <label for="">PHI No</label>
                                        </div>
                                        <div class="col-md-8">
                                            <input type="text" name="" id="" class="form-control" placeholder="private health insurance No..">
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-md-4 text-left ">
                                            <label for="">Do you have DVA card:</label>
                                        </div>
                                        <div class="col-md-8" style="text-align: left !important;">
                                            <input [ngModelOptions]="{standalone: true}" (change)='DvaStatus("Yes")' [(ngModel)]='DVACardStatus' type="radio" id="DVA card" name="dvaCode" value="Yes">
                                            <label for="DVA card" class="pl-2 pr-5">Yes</label>
                                            <input [ngModelOptions]="{standalone: true}" (change)='DvaStatus("No")' [(ngModel)]='DVACardStatus' type="radio" id="DVA card" name="dvaCode" value="No">
                                            <label for="DVA card" class="pl-2 ">No</label>
                                        </div>
                                    </div>

                                    <div class="row mb-3" *ngIf='DVACardStatus=="Yes"'>
                                        <div class="col-md-12">
                                            <p *ngFor='let data of DVAData' id="radioLabel">
                                                <input formControlName='dvaCode' type="radio" id="DVA" name="dvaCode" [value]="data.dvacode">
                                                <label for="DVA">{{data.dvatype}}</label>
                                            </p>
                                        </div>
                                    </div>

                                    <div class="row mb-3" *ngIf='DVACardStatus=="Yes"'>
                                        <div class="col-md-4 text-left ">
                                            <label for="">DVA Card No: <span>*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <input formControlName='dvaNo' type="text" name="" id="" class="form-control" placeholder="DVA card No...">
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-4 text-left ">
                                            <label for="">Address: <span>*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <input formControlName='address1' type="text" name="" id="" class="form-control" placeholder="Address...">
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-md-4 text-left ">
                                            <label for="">Suburb: <span>*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <input type="text" name="" id="" class="form-control" placeholder="Suburb...">
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-md-4 text-left ">
                                            <label for="">State: <span>*</span></label> </div>
                                        <div class="col-md-8">
                                            <input type="text" name="" id="" class="form-control" placeholder="Select one...">
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-md-4 text-left ">
                                            <label for="">Postal Code: <span>*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <input formControlName='postcode' type="text" name="" id="" class="form-control" placeholder="Postal No...">
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-md-4 text-left ">
                                            <label for="">Email: <span>*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <input formControlName='email' type="text" name="" id="" class="form-control" placeholder="Enter Your email...">
                                        </div>
                                    </div>


                                    <div class="row mb-3">
                                        <div class="col-md-4 text-left ">
                                            <label for="">Home Phone: <span>*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <input formControlName='homePhone' type="number" name="" id="" class="form-control" placeholder="Home Phone...">
                                        </div>
                                    </div>


                                    <div class="row mb-3">
                                        <div class="col-md-4 text-left ">
                                            <label for="">Mobile No: <span>*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <input formControlName='mobilePhone' type="number" name="" id="" class="form-control" placeholder="Mobile No...">
                                        </div>
                                    </div>


                                    <div class="row mb-3">
                                        <div class="col-md-1 text-left ">
                                            <input type="checkbox" checked class="mt-3" id="patient" name="patient" value="patient">
                                        </div>
                                        <div class="col-md-11 text-left">
                                            <label for="patient"> By becoming a patient of this Practice, you are giving the Practice team informed
                                        consent to release
                                        your health information to other health professionals (i.e., specialist, hospitals etc) for the
                                        purpose of
                                        management and continuity of care. Your information will be kept secure and confidential and will
                                        not be released to other third party (i.e., insurance comp, lawyers or family member etc.) without
                                        your written consent. <span>*</span></label> </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-md-1 text-left ">
                                            <input type="checkbox" checked id="policy" name="policy" value="policy">
                                        </div>
                                        <div class="col-md-11 text-left">
                                            <label for="policy">I have read and agreed to East Hills Medical Centre privacy policy. <span>*</span></label>
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-md-1 text-left ">
                                            <input type="checkbox" checked id="practice" name="practice" value="practice">
                                        </div>
                                        <div class="col-md-11 text-left">
                                            <label for="practice"> As we are a bulk billing practice, the patient gives the treating doctor informed permission to bulk-bill Medicare appropriately on their behalf. </label>
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-md-1 text-left ">
                                            <input type="checkbox" id="recall" name="recall" value="recall">
                                        </div>
                                        <div class="col-md-11 text-left">
                                            <label for="recall"> I also consent to receive recall/reminder by SMS. If you wish NOT to participate please tick this box</label>
                                        </div>
                                    </div>

                                    <div class="bottomDiv my-5">
                                        <button [routerLink]="['/patientdetail']" routerLinkActive="router-link-active">Back</button>
                                        <button type="submit" (click)="submit()">Next</button>
                                    </div>
                                </form>
                            </div>
                        </div>




                    </div>
                </div>
            </div>


        </div>
    </div>
    <br>
    <br>
</div>

<div class="footer">
    <p>Site powered by Meditco trading as Ozbiztech Pty Ltd</p>
</div>