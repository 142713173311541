import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataproviderService } from '../services/dataprovider.service';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-reasonpage',
  templateUrl: './reasonpage.component.html',
  styleUrls: ['./reasonpage.component.css']
})
export class ReasonpageComponent implements OnInit {
  responce: any;
  getReason: any;
  whichPage: any;

  loader = false;


  constructor(
    private dataservice: DataproviderService,
    public router:Router,
    public navCtrl: NgxNavigationWithDataComponent,
    private toastr: ToastrService
    ) {}

  ngOnInit(): void {
    this.loader = true;
    this.whichPage = localStorage.getItem('page');
    this.dataservice.onfetchReason().subscribe((res:any)=>{
      this.responce = res;
      this.loader = false;
    });
    var obj = {a: true, b: false, c: false, d: false}
    localStorage.setItem('timeline', JSON.stringify(obj) );
  }


  onSelectChoice(selctedChoice: any){
    this.getReason = selctedChoice;
  }

  toTimeSlot() {
      if (this.getReason == undefined) {
        this.toastr.error('Please select any reason', '', {
          timeOut: 2000,
          positionClass: 'toast-bottom-left',
          progressBar: true,
          progressAnimation: 'increasing'
        });
      } else {
        if (this.whichPage == 'firstavailable') {
          localStorage.setItem('reason', this.getReason.description);
          this.navCtrl.navigate('/timeslot');
        } else if (this.whichPage == 'particular'){
          localStorage.setItem('reason', this.getReason.description);
          this.navCtrl.navigate('/doctors');
        }
      }

  }
}





