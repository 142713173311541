<app-header></app-header>
<div class="main">
    <div class="container">

        <!--Time spanne Row # 1-->
        <div class="row first_Row">
            <div class="card first_card">
                <div class="card-body">
                    <app-makingappointment></app-makingappointment>
                </div>
            </div>
        </div>


        <!--Detail Final-->
        <div class="row pt-5 justify-content-center">
            <div class="col-12 col-md-12 col-lg-10">
                <div class="card second_Card ">
                    <h4 class="card-title">Appoinment Confirmation</h4>
                    <hr>
                    <div class="card-body MainCard_Body">
                        <div class="patientDetail">
                            <div class="row">
                                <div class="col-sm-4 firstcol ">
                                    <span id="span">Patient Name:</span>
                                </div>
                                <div class="col-sm-8 pinfo ">
                                    <label>{{patientName}}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-4 firstcol">
                                    <span id="span">Date:</span>

                                </div>
                                <div class="col-sm-8 pinfo">
                                    <label>{{DateAppoinment}}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-4 firstcol ">
                                    <span id="span">Reason of Appointment:</span>
                                </div>
                                <div class="col-sm-8 pinfo ">
                                    <label>{{reas}}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-4 firstcol ">
                                    <span id="span">Doctor:</span>
                                </div>
                                <div class="col-sm-8 pinfo ">
                                    <label>{{dcName}}</label>
                                </div>
                            </div>
                        </div>

                        <br>

                        <div class="surgeryMsg">
                            <span id="span">Surgrey Message:-</span>
                            <p>{{surgeryMsg}}</p>
                        </div>

                        <div class="bottomDiv my-5">
                            <button (click)="Back()">Back</button>
                            <button type="submit" (click)="AddAppoiment()">Next</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <br><br>
        <br><br>
    </div>
</div>

<div class="footer">
    <p>Site powered by Meditco trading as Ozbiztech Pty Ltd</p>
</div>