import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { DataproviderService } from '../services/dataprovider.service';

@Component({
  selector: 'app-see-doctor',
  templateUrl: './see-doctor.component.html',
  styleUrls: ['./see-doctor.component.css']
})
export class SeeDoctorComponent implements OnInit {

  AppoinmentObj  = this.navCtrl.get('AppoinmentObj');
  reason  = this.navCtrl.get('reason');
  doctorName  = this.navCtrl.get('doctorName');


  constructor(
    public navCtrl: NgxNavigationWithDataComponent,
    private dataservice: DataproviderService,
    private router: Router,
    public datepipe: DatePipe,
  ) { }

  ngOnInit(): void {
    if (this.AppoinmentObj  == undefined ) {
      this.router.navigate(['/firstPage']);
    }
  }

  new() {
    this.navCtrl.navigate('/NewPatient', {Appoinment: this.AppoinmentObj , reason: this.reason, doctorName: this.doctorName});
  }

  already() {
    this.navCtrl.navigate('/patientdetail', {Appoinment: this.AppoinmentObj , reason: this.reason, doctorName: this.doctorName});
  }

}
